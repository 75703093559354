import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [text, setText] = useState('');
  const [summary, setSummary] = useState('');
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focusArea, setFocusArea] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSummary(null);
    setMetrics(null);

    console.log('Submitting text:', text);
    console.log('Focus Area:', focusArea);
    console.log('API URL:', process.env.REACT_APP_API_URL);

    if (!text.trim()) {
      alert('Please enter some text to summarize');
      setLoading(false);
      return;
    }
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/summarize`, {
        text: text.trim(),
        focus_area: focusArea || null,
        max_length: 150,
        min_length: 50
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        timeout: 30000,  // 30 second timeout
        withCredentials: false  // Explicitly set to false
      });
      
      console.log('Full API Response:', response);
      
      if (response.data && response.data.summary) {
        setSummary(response.data.summary);
        setMetrics(response.data.metrics || {});
      } else {
        console.error('Unexpected response structure:', response);
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('API Call Error:', error);
      
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Error Response Data:', error.response.data);
        console.error('Error Response Status:', error.response.status);
        
        const errorDetail = error.response.data?.detail || 
                            error.response.data?.errors?.[0]?.msg || 
                            `Server Error: ${error.response.status}`;
        
        alert(errorDetail);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        alert('No response from server. Please check your network connection.');
      } else {
        // Something happened in setting up the request
        console.error('Error setting up request:', error.message);
        alert(`Request Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#0B1123] text-white font-sans">
      <div className="container mx-auto px-6 py-8">
        {/* Header */}
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">CELESTIAL LEARNING</h1>
          <div className="flex space-x-4">
            <button className="bg-blue-600 bg-opacity-20 px-4 py-2 rounded-lg">HOME</button>
            <button className="bg-blue-600 bg-opacity-20 px-4 py-2 rounded-lg">FLASHCARDS</button>
          </div>
        </header>

        {/* Main Content */}
        <main className="max-w-4xl mx-auto">
          <div className="bg-[#1A2B4A] rounded-xl p-8 shadow-2xl">
            <h2 className="text-2xl font-semibold mb-6 text-center">AI Text Summarizer</h2>
            
            {/* Text Input Area */}
            <form onSubmit={handleSubmit} className="space-y-4">
              <textarea 
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter your text here..."
                className="w-full h-48 bg-[#253659] text-white p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              {/* Focus Area Input */}
              <input 
                type="text"
                value={focusArea}
                onChange={(e) => setFocusArea(e.target.value)}
                placeholder="Optional: Specify focus area (e.g., technology, education)"
                className="w-full bg-[#253659] text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              
              {/* Submit Button */}
              <button 
                type="submit" 
                disabled={loading}
                className={`w-full py-3 rounded-lg transition-colors duration-300 ${
                  loading 
                    ? 'bg-gray-600 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
                }`}
              >
                {loading ? 'Generating Summary...' : 'Summarize'}
              </button>
            </form>

            {/* Summary Display */}
            {summary && (
              <div className="mt-8 bg-[#253659] p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Summary</h3>
                <p className="text-gray-300">{summary}</p>
                
                {metrics && (
                  <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
                    <div>
                      <strong>Original Length:</strong> {metrics.original_length} words
                    </div>
                    <div>
                      <strong>Summary Length:</strong> {metrics.summary_length} words
                    </div>
                    <div>
                      <strong>Compression Ratio:</strong> {metrics.compression_ratio.toFixed(2)}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
